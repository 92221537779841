import React, { useEffect } from 'react'
import './style.scss'
import SEO from '../../components/seo'
import { useInView } from 'react-intersection-observer'
// import { Link } from "gatsby"
import TextReveal from '../../components/TextReveal'
import Video from '../../components/Video/Video'

function RacingTourPage() {

  const [refHero, heroInView] = useInView({ triggerOnce: true })
  const [refGrid, gridInView] = useInView({ triggerOnce: true })
  const [refForm, formInView] = useInView({ triggerOnce: true })
  const [refAbout, aboutInView] = useInView({ triggerOnce: true })
  const [refDates, datesInView] = useInView({ triggerOnce: true })

  useEffect(() => {
    const barTop = document?.getElementById('bar-top')
    const masterHeader = document?.getElementById('master-header-btn')
    const sticky = document?.getElementById('hero-tour')
    if (sticky) {
      sticky.style.paddingTop = barTop.offsetHeight + masterHeader.offsetHeight + 10 + 'px'
    }
  }, [])

  const videoJsOptions = {
    autoplay: false,
    controls: true,
    loop: false,
    poster: "/DATA/IMG/PNG/video-img.png",
    muted: false,
    sources: [
      {
        src: "/DATA/VIDEO/tour_video.mp4",
        type: 'video/mp4',
      },
    ],
  }

  function handleClick(e) {
    e.preventDefault()

    if (typeof window !== 'undefined') {
      // let scrollValue = window.pageYOffset + document.querySelector('#form')?.getBoundingClientRect().top
      const anchor = document.querySelector('#form')
      const anchorHeight = Math.round(anchor?.offsetHeight)
      const viewPortHeight = window.innerHeight
      const pageY = window.pageYOffset
      const hashTop = Math.round(document.querySelector('#form')?.getBoundingClientRect().top)

      window.setTimeout(() => window.scrollTo({
        top: pageY + hashTop - (viewPortHeight - anchorHeight) / 2,
        behavior: 'smooth'
      }), 200)
    }
  }

  return (
    <main id="racing-tour">

      <SEO title="1/ST Racing Tour" description="An unprecedented first of its kind, the 1/ST Racing Tour heralds the very best in sport with world class Thoroughbred horse racing, unrivaled competition, elite jockeys, unforgettable experiences, and leading entertainment all at iconic venues. Millions are at stake for horsemen and bettors across more than 50 Grade 1 stakes races coast-to-coast, from Gulfstream Park to Santa Anita and culminating at historic Pimlico race course. New champions will be crowned at extraordinary racing experiences, only at 1/ST." />

      <section id="hero-tour" ref={refHero} className={`section-hero text-center ${heroInView ? 'is-inview' : ''} `} >
        <div className='hero-tour-main-wrap'>
          <div className='hero-tour-bg left-bg'>
            <div className="positioner-img-1">
              <div className="aspect-ratio">
                <img src={'/DATA/IMG/JPG/img-1.jpg'} className={["img-cover", "lazyload", "fade", heroInView ? "lazyloaded" : null].join(" ")} alt="1st Racing" />
              </div>
              <div className="aspect-ratio">
                <img src={'/DATA/IMG/JPG/img-2.jpg'} className={["img-cover", "lazyload", "fade", heroInView ? "lazyloaded" : null].join(" ")} alt="1st Racing" />
              </div>
            </div>
            {/* <div className="positioner-img-2">
              <div className="aspect-ratio">
                <img src={'/DATA/IMG/JPG/img-2.jpg'} className={["img-cover", "lazyload", "fade", heroInView ? "lazyloaded" : null].join(" ")} alt="1st Racing" />
              </div>
            </div> */}
            <div className="positioner-img-3">
              <div className="aspect-ratio">
                <img src={'/DATA/IMG/JPG/img-3.jpg'} className={["img-cover", "lazyload", "fade", heroInView ? "lazyloaded" : null].join(" ")} alt="1st Racing" />
              </div>
            </div>
          </div>
          <div className='hero-tour-bg right-bg'>
            <div className="positioner-img-4">
              <div className="aspect-ratio">
                <img src={'/DATA/IMG/JPG/img-4.jpg'} className={["img-cover", "lazyload", "fade", heroInView ? "lazyloaded" : null].join(" ")} alt="1st Racing" />
              </div>
            </div>
          </div>
          <div className='hero-tour-wrap text-center'>
            <a className='uppercase form-link' href="#form" onClick={(e) => handleClick(e)}>
              <TextReveal size={"h5"} theme="margin-0" text={"Click Here For"} />
              <TextReveal size={"h5"} theme="margin-0 fancy-font" text={"<mark>1/ST Access</mark>"} />
            </a>
            <div className="hero-tour-wrap-intro">
              <div className='hero-logo'>
                <div className='aspect-ratio-16-5'>
                  <img src={'/DATA/IMG/PNG/racing_tour_logo.png'} className={["img-contain", "lazyload", "fade", heroInView ? "lazyloaded" : null].join(" ")} alt="1st Racing Tour Logo" />
                </div>
              </div>
              <TextReveal size={"h1"} text={"1/ST Racing"} />
              <TextReveal size={"h1"} theme="fancy-font contrast" text={"tour"} />
              <h4 className='animate-in-from-bottom delay-250ms'>2023</h4>
              <div className='flex-dates'>
                <h4 className='flex-heading animate-in-from-bottom delay-375ms'><span>January 28</span><span>Pegasus World Cup</span></h4>
                <h4 className='flex-heading animate-in-from-bottom delay-500ms'><span>February 4</span><span>Holy Bull & Robert B. Lewis</span></h4>
                <h4 className='flex-heading animate-in-from-bottom delay-625ms'><span>March 4</span><span>Fountain of Youth & Big 'Cap</span></h4>
                <h4 className='flex-heading animate-in-from-bottom delay-750ms'><span>April 1</span><span>Florida Derby</span></h4>
                <h4 className='flex-heading animate-in-from-bottom delay-875ms'><span>April 8</span> <span>Santa Anita Derby</span></h4>
                <h4 className='flex-heading animate-in-from-bottom delay-1000ms'><span>May 20</span> <span>Preakness 148</span></h4>
              </div>
            </div>
            <div className='hero-tour-wrap-logos grid-12 padd-top'>
              <div className='span-6'></div>
              <div className='span-6 span-12-mobile c0 grid-12'>

                <a href="https://www.gulfstreampark.com" target="_blank" rel="noreferrer noopener" className='nostrike span-3 padd-2 single-logo'>
                  <div className="logo-size-limiter">
                    <div className='aspect-ratio-16-9'>
                      <img src={'/DATA/IMG/PNG/1.png'} className={["img-contain", "lazyload", "fade", heroInView ? "lazyloaded" : null].join(" ")} alt="Gulfstream Park Logo" />
                    </div>
                  </div>
                  <p className='venue-title blue'>Gulfstream <br /> Park</p>
                </a>

                <a href="https://www.santaanita.com" target="_blank" rel="noreferrer noopener" className='nostrike span-3 padd-2 single-logo'>
                  <div className="logo-size-limiter sap">
                    <div className='aspect-ratio-16-9'>
                      <img src={'/DATA/IMG/PNG/2.png'} className={["img-contain", "lazyload", "fade", heroInView ? "lazyloaded" : null].join(" ")} alt="Santa Anita Park Logo" />
                    </div>
                  </div>
                  <p className='venue-title green'>Santa Anita <br /> Park</p>
                </a>

                <a href="https://www.laurelpark.com" target="_blank" rel="noreferrer noopener" className='nostrike span-3 padd-2 single-logo'>
                  <div className="logo-size-limiter">
                    <div className='aspect-ratio-16-9'>
                      <img src={'/DATA/IMG/PNG/3.png'} className={["img-contain", "lazyload", "fade", heroInView ? "lazyloaded" : null].join(" ")} alt="Maryland Jockey Club Logo" />
                    </div>
                  </div>
                  <p className='venue-title red'>Maryland <br /> Jockey Club</p>
                </a>

                <a href="https://www.goldengatefields.com" target="_blank" rel="noreferrer noopener" className='nostrike span-3 padd-2 single-logo'>
                  <div className="logo-size-limiter">
                    <div className='aspect-ratio-16-9'>
                      <img src={'/DATA/IMG/PNG/4.png'} className={["img-contain", "lazyload", "fade", heroInView ? "lazyloaded" : null].join(" ")} alt="Golden Gate Fields Logo" />
                    </div>
                  </div>
                  <p className='venue-title yellow'>Golden Gate <br /> Field</p>
                </a>

              </div>
            </div>
          </div>
        </div>
      </section>

      <section ref={refGrid} className={`section-grid padd-top padd-bottom text-center grid-12 ${gridInView ? 'is-inview' : ''} `} >
        <div className='photo-grid span-12'>

          <div className='single-img padd-2'>
            <div className='aspect-ratio'>
              <img src={'/DATA/IMG/JPG/grid-1.jpg'} className={["delay-250ms", "img-cover", "lazyload", "fade", gridInView ? "lazyloaded" : null].join(" ")} alt="Best Experiences" />
            </div>
            <h6 className='animate-in-from-bottom delay-250ms'>Best In-venue Experiences</h6>
          </div>

          <div className='single-img padd-2'>
            <div className='aspect-ratio'>
              <img src={'/DATA/IMG/JPG/grid-2.jpg'} className={["delay-375ms", "img-cover", "lazyload", "fade", gridInView ? "lazyloaded" : null].join(" ")} alt="Wagering Tournament" />
            </div>
            <h6 className='animate-in-from-bottom delay-375ms'>1/ST Bet Wagering Tournament</h6>
          </div>

          <div className='single-img padd-2'>
            <div className='aspect-ratio'>
              <img src={'/DATA/IMG/JPG/grid-3.jpg'} className={["delay-500ms", "img-cover", "lazyload", "fade", gridInView ? "lazyloaded" : null].join(" ")} alt="50+ Stakes Races" />
            </div>
            <h6 className='animate-in-from-bottom delay-500ms'>50+ Stakes Races</h6>
          </div>

          <div className='single-img padd-2'>
            <div className='aspect-ratio'>
              <img src={'/DATA/IMG/JPG/grid-4.jpg'} className={["delay-625ms", "img-cover", "lazyload", "fade", gridInView ? "lazyloaded" : null].join(" ")} alt="Bonus Incentives" />
            </div>
            <h6 className='animate-in-from-bottom delay-625ms'>New Owner/ Trainer Bonus Incentives</h6>
          </div>

          <div className='single-img padd-2'>
            <div className='aspect-ratio'>
              <img src={'/DATA/IMG/JPG/grid-5.jpg'} className={["delay-750ms", "img-cover", "lazyload", "fade", gridInView ? "lazyloaded" : null].join(" ")} alt="3 World Class Venues" />
            </div>
            <h6 className='animate-in-from-bottom delay-750ms'>3 World Class Venues</h6>
          </div>

        </div>
      </section>

      <section ref={refForm} className={` section-form ${formInView ? 'is-inview' : ''} `} >
        <div id="form" className='anchor'></div>
        <div className='grid-12'>
          <div className='span-6 col-video'>
            <div className={["hero-video-bg"].join(" ")} role="button" tabIndex={0} >
              <div className="video-expander animate-in-from-right delay-1000ms">
                <div className="aspect-ratio-16-9">
                  <Video {...videoJsOptions} />
                  <div className="decorative-arrow"></div>
                </div>
              </div>
            </div>
          </div>
          <div className='span-6 col-form padd-2 padd-bottom-off uppercase'>
            <div className='text-center hero-tour-wrap'>
              <h4>2023</h4>

              <div className="hero-tour-wrap-intro text-center">
                <div className='hero-logo'>
                  <div className='aspect-ratio-16-5'>
                    <img src={'/DATA/IMG/PNG/racing_tour_logo_dark.png'} className={["img-contain", "lazyload", "fade", formInView ? "lazyloaded" : null].join(" ")} alt="Racing Tour Logo Dark" />
                  </div>
                </div>
              </div>
            </div>
            <TextReveal text={"Get<br/><mark>1/ST Access</mark>"} />
            <p className='content'>Exclusive Ticket Offerings <br /> Premium Seating ♦ Priority Access <br /> Season Ticket Packages ♦ Vip Hospitality <br /> Access To Pre-race fan events</p>
            <iframe
              id="form-tour"
              src="https://cloud.experience.1st.com/racing_tour_2023"
              frameBorder="0"
              style={{ order: '100', backgroundColor: 'transparent', width: '100vw', maxWidth: '100%', padding: '1em 0', margin: '0 auto', minHeight: '285px', height: '285px' }}
              title="Contact Form"
            />
          </div>
        </div>
      </section>

      <section ref={refAbout} className={`section-about ${aboutInView ? 'is-inview' : ''} `} >
        <div className='grid-12 padd-bottom'>
          <div className='span-3  green-bg'></div>
          <div className='span-6 span-9-mobile padd content padd-top-3 padd-bottom-3'>
            <TextReveal size="h2" text={"About"} />
            <p>An unprecedented first of its kind, the 1/ST Racing Tour heralds the very best in sport with world class Thoroughbred horse racing, unrivaled competition, elite jockeys, unforgettable experiences, and leading entertainment all at iconic venues.<br /> Millions are at stake for horsemen and bettors across more than 50 graded stakes races coast-to-coast, from Gulfstream Park to Santa Anita and culminating at historic Pimlico Race Course. New champions will be crowned at extraordinary racing experiences, only at 1/ST.</p>
          </div>
          <div className='span-3 span-12-mobile c0'></div>
          <div className='span-3 span-12-mobile'></div>
          <div className='span-6 span-9-mobile col-img'>
            <div className='aspect-ratio-16-7'>
              <img src={'/DATA/IMG/JPG/track.jpg'} className={["img-cover", "lazyload", "fade", aboutInView ? "lazyloaded" : null].join(" ")} alt="Track" />
            </div>
          </div>
          <div className='span-3 hero-tour-wrap col-logo'>
            <div className="hero-tour-wrap-intro text-center">
              <div className='hero-logo'>
                <div className='aspect-ratio-16-5'>
                  <img src={'/DATA/IMG/PNG/racing_tour_logo_dark.png'} className={["img-contain", "lazyload", "fade", aboutInView ? "lazyloaded" : null].join(" ")} alt="Racing tour logo dark" />
                </div>
              </div>
              <TextReveal size={"h3"} text={"1/ST Racing"} />
              <TextReveal size={"h3"} theme="fancy-font contrast" text={"tour"} />
              <h4>2023</h4>
            </div>
          </div>
        </div>
      </section>

      <section ref={refDates} className={`section-dates grid-12 ${datesInView ? 'is-inview' : ''} `} >
        <div className='span-9 span-12-mobile border flex jc-flex-end'>
          <TextReveal size="h2" theme="h1" text={"<div>Jan <span class='arrow-container'></span> May / 23</div>"} />
        </div>
        <div className='span-7 span-12-mobile padd-top padd-bottom-2'>
          <div className='flex-dates'>
            <h4 className='flex-heading animate-in-from-bottom delay-250ms'><span>January 28</span><span>Pegasus World Cup</span></h4>
            <h4 className='flex-heading animate-in-from-bottom delay-375ms'><span>February 4</span><span>Holy Bull & Robert B. Lewis</span></h4>
            <h4 className='flex-heading animate-in-from-bottom delay-500ms'><span>March 4</span><span>Fountain of Youth & Big 'Cap</span></h4>
            <h4 className='flex-heading animate-in-from-bottom delay-625ms'><span>April 1</span><span>Florida Derby</span></h4>
            <h4 className='flex-heading animate-in-from-bottom delay-750ms'><span>April 8</span> <span>Santa Anita Derby</span></h4>
            <h4 className='flex-heading animate-in-from-bottom delay-875ms'><span>May 20</span> <span>Preakness 148</span></h4>
          </div>
        </div>
      </section>

    </main >
  )
}

export default RacingTourPage